import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import React, { Fragment, useState } from 'react';
import { useWindowDimensions } from '../../hooks/useDimension';

import { signUp } from '../../api/auth';

import s from './index.module.scss';
import validations from '../../utils/validations';

import Seo from '../../components/Seo';
import Input from '../../components/input';
import Button from '../../components/button';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import RadioInput from '../../components/radioInput/index';

const Register = () => {
  const { width } = useWindowDimensions();
  const { handleSubmit, errors, register, getValues } = useForm();
  const [checkbox, setCheckbox] = useState(false);

  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);

    signUp(values)
      .then(() => {
        navigate('/home');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const toogleCheckbox = () => {
    //cambiamos el estilo del checkbox falso dependiendo de si el checkbox real esta activado o no
    setCheckbox(document.getElementById('terms').checked);
  };

  return (
    <Fragment>
      <Seo
        lang="es"
        description="Registrarse en coronasunsetchile.com"
        title="Registrarse"
      />

      <Header title="Registrarme" />

      <div className={s.container}>
        <h1 >Para poder pagar primero necesitamos registrarte:</h1>

        <form onSubmit={handleSubmit(onSubmit)} className={s.inputsContainer}>
          <div className={s.boxTop}>
            <div className={s.principales}>
              <h2 className={s.h2}>1. Registra tus datos principales:</h2>

              <Input
                className="field"
                err={errors.fullName}
                type="text"
                name="fullName"
                placeholder="Nombre y Apellido"
                referencia={register(validations.required)}
                width="50%"
              />

              <Input
                className="field"
                err={errors.personalIdentification}
                type="text"
                name="personalIdentification"
                placeholder="RUT o Pasaporte"
                referencia={register(validations.required)}
                width="50%"
              />

              <Input
                className="field"
                err={errors.email}
                type="text"
                name="email"
                placeholder="Correo Electrónico"
                referencia={register(validations.email)}
                width="50%"
              />

              <Input
                className="field"
                err={errors.cellphone}
                type="text"
                name="cellphone"
                placeholder="Teléfono"
                referencia={register(validations.required)}
                width="50%"
              />

              <Input
                width="50%"
                type="password"
                name="password"
                className="field"
                placeholder="Contraseña"
                err={errors.password}
                referencia={register(validations.password)}
              />

              <Input
                width="50%"
                type="password"
                className="field"
                name="confirmPassword"
                placeholder="Confirmar contraseña"
                err={errors.confirmPassword}
                referencia={register({
                  required: 'Contraseña inválida',
                  validate: (value) => {
                    if (value === getValues().password) {
                      return true;
                    }
                    return 'Las contraseñas deben coincidir';
                  },
                })}
              />
            </div>

            <div className={s.principales}>
              <h2 className={s.h2}>2. ¿dónde te podemos recoger?</h2>
              <Input
                className="field"
                err={errors.santiagorm}
                type="text"
                name="santiagorm"
                placeholder="Santiago RM"
                referencia={register(validations.required)}
                width="50%"
              />
              <Input
                className="field"
                err={errors.comuna}
                type="text"
                name="comuna"
                placeholder="Comuna"
                referencia={register(validations.required)}
                width="50%"
              />
              <Input
                className="field"
                err={errors.address}
                type="text"
                name="address"
                placeholder="Calle, Nro"
                referencia={register(validations.required)}
                width="50%"
              />
              <Input
                className="field"
                err={errors.depto}
                type="text"
                name="depto"
                placeholder="Depto, Oficina, Casa"
                referencia={register(validations.required)}
                width="50%"
              />
              <Input
                className="field"
                err={errors.extra}
                type="text"
                name="extra"
                placeholder="Indicaciones Extras"
                referencia={register()}
                width="50%"
              />
              <label
                htmlFor="terms"
                className={s.check}
                onClick={toogleCheckbox}
              >
                <RadioInput
                  id="terms"
                  name="pass"
                  className="checkbox"
                  type="checkbox"
                  styles={{ opacity: '0' }}
                />
                <div
                  className={checkbox ? s.checkboxTrue : s.checkboxFalse}
                ></div>
                <small>
                  He leído y acepto bases y condiciones, políticas de privacidad
                  y activaciones de marketing.
                </small>
              </label>

              {width > 768 && (
                <Button
                  type="submit"
                  className="auth"
                  value="Siguiente"
                  loading={loading}
                  styles={{
                    background: '#ffc72c',
                    width: '20%',
                    margin: '18px 0px',
                  }}
                />
              )}
            </div>
          </div>

          <div className={s.principales}>
            <h2 className={s.h2}>3. Ingresá los mails de tus amigos</h2>
            <Input
              width="50%"
              type="text"
              className="field"
              name="emailFriend1"
              placeholder="Correo Electrónico 1"
              err={errors.emailFriend}
              referencia={register(validations.emailFriend)}
            />

            {width <= 768 && (
              <Button
                type="submit"
                className="auth"
                value="Siguiente"
                loading={loading}
                styles={{
                  background: '#ffc72c',
                  width: '20%',
                  margin: '18px 0px',
                }}
              />
            )}
          </div>
        </form>

        <Footer styles={{ position: width <= 1440 ? 'static' : 'absolute' }} />
      </div>
    </Fragment>
  );
};

export default Register;
